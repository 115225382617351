<template>

  <div>
    <div class="card cr-card" style="margin:0px 30px 30px 30px;">
      <div class="card-body">
        <h5 class="card-title">Extended Length Trips</h5>
        <h6 class="card-subtitle mb-2 text-muted">Included in the relevant Total Sailboat/Powerboat Experience</h6>
        <div class="table-responsive-xxl">
          <table class="cr-resume-table table table-striped table-hover">
            <thead>
            <tr>
              <th>Dates</th>
              <th>Days</th>
              <th>Nights</th>
              <th style="white-space: nowrap;">Vessel Name</th>
              <th style="white-space: nowrap;">Vessel Owner</th>
              <th style="white-space: nowrap;">Vessel Info</th>
              <th>Location</th>
              <th style="white-space: nowrap;">Location Type</th>
              <th>Role</th>
              <th>Purpose</th>
              <th style="white-space: nowrap;">Verified By</th>
            </tr>
            </thead>
            <tbody v-if="sectionData && sectionData.length > 0">
              <tr v-for="trip in sectionData" :key="trip.id">
                <td v-html="formatTripDates(trip)"></td>
                <td>{{trip.totalDays}}</td>
                <td>{{trip.totalNights}}</td>
                <td>{{trip.vesselName}}</td>
                <td v-html="formatBoatOwner(trip)"></td>
                <td v-html="formatBoatInfo(trip)"></td>
                <td>{{trip.location}}</td>
                <td v-html="formatLocationType(trip)"></td>
                <td v-html="formatRole(trip)"></td>
                <td v-html="formatPurpose(trip)"></td>
                <td  v-html="formatVerificationType(trip)"></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="11" class="text-center">Sailor has not completed any extended trips.</td>
              </tr>
            </tbody>

          </table>
        </div>
        <div class="cr-resume-footer-note">Charter company verification is the highest trusted verification. This means the charter company either directly verified this trip or Charter Rode verified the trip details with the charter company. Crewmate verification is considered to be very reliable as it is completed by crewmate on the vessel at the time. Self verification may need to be verified directly with the sailor.</div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'ResumeSectionExtendedTripsView',
  props: {
    'sectionData': Object
  },
  data() {
    return {
      uiDropdowns: this.$store.state.uiDropdowns,
    }
  },
  methods: {
    formatTripDates(trip) {
      // trip.date is in format YYY-MM-DD
      const monthsOfYear = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let tripStart = trip.startDate.split('-')
      let tripEnd = trip.endDate.split('-')
      if ( tripStart[0] === tripEnd[0] ) { // years match
        if (tripStart[1] === tripEnd[1] ) { // months match
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+tripEnd[2]+', '+tripStart[0]
        } else { // months mismatch
          return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripStart[0]
        }
      } else { // years mismatch...
        return monthsOfYear[parseInt(tripStart[1])]+' '+tripStart[2]+', '+tripStart[0]+'-'+monthsOfYear[parseInt(tripEnd[1])]+' '+tripEnd[2]+', '+tripEnd[0]
      }
    },
    formatRole(trip) {
      let result = ''
      if ( trip.role === 'captain' ) {
        result = 'Skipper' // backwards compatability
      } else {
        this.uiDropdowns.roleType.forEach(function (aRole) {
          if (aRole.value === trip.role) {
            result = aRole.label
          }
        })
      }
      return result
    },
    formatPurpose(trip) {
      let result = ''
      this.uiDropdowns.purpose.forEach(function(aPurpose) {
        if ( aPurpose.value === trip.purpose ) {
          result = aPurpose.label
        }
      })
      return result
    },
    formatLocationType(trip) {
      let result = ''
      this.uiDropdowns.locationDescription.forEach(function(aLocType) {
        if ( aLocType.value === trip.locationType ) {
          result = aLocType.label
        }
      })
      return result
    },
    formatVerificationType(trip) {
      if ( trip.verificationType === 'self' ) {
        return 'Self'
      } else if ( trip.verificationType === 'crewmate' ) {
        return 'Crewmate'
      } else if ( trip.verificationType === 'charter_rode' || trip.verificationType === 'charter_company' ) {
        return 'Charter Company'
      }
    },
    formatBoatOwner(trip) {
      if ( trip.charterCompany ) {
        return trip.charterCompany.charterCompanyBaseName
      } else if ( trip.boatOwner === 'self' ) {
        return 'Mine'
      } else if ( trip.boatOwner === 'friend' ) {
        return 'Friend'
      } else if ( trip.boatOwner === 'charterCompany' ) {
        if ( trip.chartCompanyName != undefined && trip.charterBase != undefined ) {
          return trip.chartCompanyName +' - '+trip.charterBase
        } else if ( trip.chartCompanyName != undefined ) {
          return trip.chartCompanyName
        }
        return 'Charter Company'
      } else if ( trip.boatOwner === 'commercial' ) {
        return 'Commercial'
      }
    },
    formatBoatInfo(trip) {
      let vesselLengthMeters = parseFloat(trip.vesselLength).toFixed(2)
      let vesselLengthFeet = (parseFloat(trip.vesselLength) * 3.2808).toFixed(0)
      let vesselLengthFormatted = vesselLengthMeters +'m ('+vesselLengthFeet+' ft)'
      if ( trip.boatType === 'sail' ) {
        if (trip.hulls === 1) {
          return 'Sailing Monohull ' + vesselLengthFormatted
        } else if (trip.hulls === 2) {
          return 'Sailing Catamaran ' + vesselLengthFormatted
        } else if (trip.hulls === 3) {
          return 'Sailing Trimaran ' + vesselLengthFormatted
        } else {
          return 'Sailboat ' + trip.vesselLength + 'm'
        }
      } else if ( trip.boatType === 'power' ) {
        if (trip.hulls === 1) {
          return 'Powerboat ' + vesselLengthFormatted
        } else if (trip.hulls === 2) {
          return 'Powercat ' + vesselLengthFormatted
        } else if (trip.hulls === 3) {
          return 'Powered Trimaran ' + vesselLengthFormatted
        } else {
          return 'Powerboat ' + vesselLengthFormatted
        }
      }
    },
    formatResumeDaysCell(aCell) {
      if ( aCell.days > 0 ) {
        return '<div>'+aCell.days+'<span style="position:relative;top:-1px;font-size: 10px;color:#888;"> | '+aCell.verified.percent+'</span></div><div style="font-size: 10px;color:#888;">'+aCell.verified.crewmate+'-'+aCell.verified.charterCompany+'</div>'
      } else {
        return '--'
      }
    }
  }
}
</script>

<style>
.cr-resume-table {
  font-size:12px;
  width: 100%;
}

.cr-resume-table th {
  background: #f6f6f6;
}

.cr-resume-background-none {
  background: transparent!important;
}

.cr-resume-border-top {
  border-top: 1px solid #ccc;
}
.cr-resume-border-right {
  border-right: 1px solid #ccc;
}

.cr-resume-border-top-none {
  border-top:none!important;
}
.cr-resume-border-bottom-none {
  border-bottom:none!important;
}

.cr-resume-footer-note {
  font-size: 11px;
  color:#999;
}
</style>