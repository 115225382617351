import { createRouter, createWebHashHistory } from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router'

// 'views' that are routes
import ResumeLookup from '../views/Home.vue'
import PublicResume from '../views/Resume.vue'
import UserPrintResume from '../views/PrintResume.vue'

const appSubDirectory = process.env.VUE_APP_CHARTER_RODE_APP_SUBDIRECTORY

// our routes
const routes = [
    { path: '/', component: ResumeLookup, name: 'ResumeLookup', meta: {requiresAuth: false}},
    { path: '/print/:sailorId', component: UserPrintResume, name: 'UserPrintResume', meta: {requiresAuth: true}},
    { path: '/:sailorId', component: PublicResume, name: 'PublicResume', meta: {requiresAuth: false}}
]

const routerHistory = createWebHashHistory()
// const routerHistory = createWebHistory(appSubDirectory)
const router = createRouter({
    history: routerHistory,
    base: appSubDirectory,
    strict: true,
    routes: routes,
    scrollBehavior () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left:0, top:0})
            }, 10)
        })
    }
})

// // remove trailing slashes
router.beforeEach((to, from, next) => {
    if ( window.location && window.location.pathname && window.location.pathname.length > 1 ) {
        // rewrite this to see if we can use it to lookup sailor
        window.location = window.location.origin + "/#/" + window.location.pathname.substring(1, window.location.pathname.length)
    } else {
        next() // go to wherever I'm going
    }
})

export default router