
import { createStore } from 'vuex'

// Create a new store instance
// critical components are backed by the localStorage so that if someone
// clicks the refresh screen then the right thing happens and we don't
// loose all of the info
const store = createStore({
    state () {
        return {
            uiDropdowns: localStorage.getItem('uiDropdowns') ? JSON.parse(localStorage.getItem('uiDropdowns')) : undefined
        }
    },
    mutations: {
        setUiDropdowns(state, uiDropdowns) {
            localStorage.setItem('uiDropdowns', JSON.stringify(uiDropdowns))
            state.uiDropdowns = uiDropdowns
        }
    },
    getters: {

    }
})

export default store