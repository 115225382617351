<template>
  <div style="margin-bottom: 50px;">
    <div class="container-fluid">
      <div class="row mt-3 mb-3">
        <div class="col-sm-12">
          <div style="text-align: center;">
            <h4 v-if="!noEntriesFound">Resume for {{sailorName}}</h4>
            <h4 v-if="noEntriesFound">Resume Not Found</h4>
          </div>
          <div class="charterRodeHelpText">The practical experience reported is self stated by sailor. Verified experience reported here has been verified either by crewmate or by charter company digital verification. Charter company verification is the highest trusted verification. Crewmate digital verification is considered to be very reliable. Self verification may need to be verified directly with the sailor. Charter Rode has no means of totally verifying the validity of the stated experience and therefore accepts no responsibility including for false or misleading statements.</div>
        </div>
      </div>
    </div>

    <div v-if="lookingUpResume">
      <div class="tx-submit-spinner mt-lg-5"><fai icon="spinner" spin/> Retrieving resume ...</div>
    </div>

    <div v-if="noEntriesFound" style="margin-bottom: 90px;">
      <div class="tx-submit-spinner mt-lg-5"><fai icon="exclamation-triangle"/> Uh-oh. There is no experience found for this sailor on the Sailing Resume Blockchain.</div>
      <div class="container2" style="margin: 0px 50px;">

            <div class="text-center charterRodeHelpText mt-3">This may occur if you have a typo in the Sailor Id; if this sailor has not yet logged any experience; or if the experience for this sailor has not yet been verified. Only Unconfirmed and Confirmed transactions appear on a Charter Rode Trusted Sailing Resume.</div>


      </div>
    </div>

    <div v-if="!lookingUpResume && !noEntriesFound">
      <div class="nav nav-pills mb-3" id="crResumeNavTabs" role="tablist" style="padding-left: 30px!important;">
        <button class="nav-link active" id="nav-summary-tab" data-bs-toggle="tab" data-bs-target="#cr-res-summary" type="button" role="tab" aria-controls="nav-summary" aria-selected="true">Summary</button>
        <button class="nav-link" id="nav-sailboat-tab" data-bs-toggle="tab" data-bs-target="#cr-res-sailboat" type="button" role="tab" aria-controls="nav-sailboat" aria-selected="false">Sailboat</button>
        <button class="nav-link" id="nav-powerboat-tab" data-bs-toggle="tab" data-bs-target="#cr-res-powerboat" type="button" role="tab" aria-controls="nav-powerboat" aria-selected="false">Powerboat</button>
        <button class="nav-link" id="nav-vessels-tab" data-bs-toggle="tab" data-bs-target="#cr-res-vessels" type="button" role="tab" aria-controls="nav-vessels" aria-selected="false">Vessels</button>
        <button class="nav-link" id="nav-extendedtrips-tab" data-bs-toggle="tab" data-bs-target="#cr-res-trips" type="button" role="tab" aria-controls="nav-trips" aria-selected="false">Extended Trips</button>
        <button class="nav-link" id="nav-opsexp-tab" data-bs-toggle="tab" data-bs-target="#cr-res-opsexp" type="button" role="tab" aria-controls="nav-opsexp" aria-selected="false">Operational Experience</button>
        <button class="nav-link" id="nav-credentials-tab" data-bs-toggle="tab" data-bs-target="#cr-res-credentials" type="button" role="tab" aria-controls="nav-credentials" aria-selected="false">Credentials</button>

        <a v-if="resumePrintUrl" :href="resumePrintUrl" class="nav-link text-secondary" style="font-weight: normal!important;"><fai :icon="[ 'far', 'file']"/>&nbsp;Print Resume</a>
      </div>

      <div class="tab-content" id="crResumeTabContent">

        <div class="tab-pane fade show active" id="cr-res-summary" role="tabpanel" aria-labelledby="cr-res-summary">

          <div class="card add-page-break" style="margin:0px 30px 30px 30px;">
            <div class="card-body">
              <div>Resume has a total of <span v-html="formatNumber(resume.summary.header.totalDaysExperienceToAdd)"></span> qualifying days, <span v-html="formatDecimal(resume.summary.header.totalNauticalMiles)"></span> nautical miles, <span v-html="formatHours(resume.summary.header.totalHours)"></span> hours, and <span v-html="formatNumber(resume.summary.header.totalEntries)"></span> entries logged.<span v-if="resume.summary.header.oldestEntryDate"> The oldest logbook entry was on {{resume.summary.header.oldestEntryDate}} and the latest logbook entry was on {{resume.summary.header.newestEntryDate}}.</span><span v-if="resume.summary.header.identityVerifiedOn"> Sailor's identity was verified by Charter Rode on {{resume.summary.header.identityVerifiedOn}}.</span> <span v-if="resume.credentials && resume.credentials.length > 0">Resume includes a total of <span v-html="formatNumber(resume.credentials.length)"></span> credentials<span v-if="resume.featuredCredentials && resume.featuredCredentials.length > 0" v-html="featuredCredentials(resume.featuredCredentials)"></span><span v-else>.</span></span></div>
            </div>
          </div>

          <resumeSectionStandard v-bind:sectionData="resume.summary.totalBoating" sectionTitle="Total Boating Experience"></resumeSectionStandard>

          <resumeSectionHullType v-bind:sectionData="resume.summary.hullType" sectionTitle="Total Boating Experience by Hull Type" sectionSubtitle="Included in the above Total Boating Experience"></resumeSectionHullType>

          <resumeSectionLocationType v-bind:sectionData="resume.summary.locationType" sectionTitle="Total Boating Experience by Location Type" sectionSubtitle="Included in the above Total Boating Experience"></resumeSectionLocationType>

          <resumeSectionBoatOwnership v-bind:sectionData="resume.summary.boatOwnership" sectionTitle="Total Boating Experience by Boat Ownership" sectionSubtitle="Included in the above Total Boating Experience"></resumeSectionBoatOwnership>

          <resumeSectionGrossTonnage v-bind:sectionData="resume.summary.grossTonnage" sectionTitle="Total Boating Experience by Gross Tonnage (GT)" sectionSubtitle="Included in the above Total Boating Experience"></resumeSectionGrossTonnage>

        </div>

        <div class="tab-pane fade show" id="cr-res-sailboat" role="tabpanel" aria-labelledby="cr-res-sailboat">

          <div class="card" style="margin:0px 30px 30px 30px;">
            <div class="card-body">
              <div>Resume has a total of <span v-html="formatNumber(resume.sailboat.header.totalDaysExperienceToAdd)"></span> qualifying days, <span v-html="formatDecimal(resume.sailboat.header.totalNauticalMiles)"></span> nautical miles, <span v-html="formatHours(resume.sailboat.header.totalHours)"></span> hours, and <span v-html="formatNumber(resume.sailboat.header.totalEntries)"></span> entries logged on sailboats.<span v-if="resume.sailboat.header.oldestEntryDate"> The oldest logbook entry was on {{resume.sailboat.header.oldestEntryDate}} and the latest logbook entry was on {{resume.sailboat.header.newestEntryDate}}.</span></div>
            </div>
          </div>

          <resumeSectionStandard v-bind:sectionData="resume.sailboat.totalBoating" sectionTitle="Sailboat Experience"></resumeSectionStandard>

          <resumeSectionHullType v-bind:sectionData="resume.sailboat.hullType" sectionTitle="Sailboat Experience by Hull Type" sectionSubtitle="Included in the above Sailboat Experience"></resumeSectionHullType>

          <resumeSectionLocationType v-bind:sectionData="resume.sailboat.locationType" sectionTitle="Sailboat Experience by Location Type" sectionSubtitle="Included in the above Sailboat Experience"></resumeSectionLocationType>

          <resumeSectionBoatOwnership v-bind:sectionData="resume.sailboat.boatOwnership" sectionTitle="Sailboat Experience by Boat Ownership" sectionSubtitle="Included in the above Sailboat Experience"></resumeSectionBoatOwnership>

          <resumeSectionGrossTonnage v-bind:sectionData="resume.sailboat.grossTonnage" sectionTitle="Sailboat Experience by Gross Tonnage (GT)" sectionSubtitle="Included in the above Sailboat Experience"></resumeSectionGrossTonnage>

        </div>

        <div class="tab-pane fade show" id="cr-res-powerboat" role="tabpanel" aria-labelledby="cr-res-powerboat">

          <div class="card" style="margin:0px 30px 30px 30px;">
            <div class="card-body">
              <div>Resume has a total of <span v-html="formatNumber(resume.powerboat.header.totalDaysExperienceToAdd)"></span> qualifying days, <span v-html="formatDecimal(resume.powerboat.header.totalNauticalMiles)"></span> nautical miles, <span v-html="formatHours(resume.powerboat.header.totalHours)"></span> hours, and <span v-html="formatNumber(resume.powerboat.header.totalEntries)"></span> entries logged on powerboats.<span v-if="resume.powerboat.header.oldestEntryDate"> The oldest logbook entry was on {{resume.powerboat.header.oldestEntryDate}} and the latest logbook entry was on {{resume.powerboat.header.newestEntryDate}}.</span></div>
            </div>
          </div>

          <resumeSectionStandard v-bind:sectionData="resume.powerboat.totalBoating" sectionTitle="Powerboat Experience"></resumeSectionStandard>

          <resumeSectionHullType v-bind:sectionData="resume.powerboat.hullType" sectionTitle="Powerboat Experience by Hull Type" sectionSubtitle="Included in the above Powerboat Experience"></resumeSectionHullType>

          <resumeSectionLocationType v-bind:sectionData="resume.powerboat.locationType" sectionTitle="Powerboat Experience by Location Type" sectionSubtitle="Included in the above Powerboat Experience"></resumeSectionLocationType>

          <resumeSectionBoatOwnership v-bind:sectionData="resume.powerboat.boatOwnership" sectionTitle="Powerboat Experience by Boat Ownership" sectionSubtitle="Included in the above Powerboat Experience"></resumeSectionBoatOwnership>

          <resumeSectionGrossTonnage v-bind:sectionData="resume.powerboat.grossTonnage" sectionTitle="Powerboat Experience by Gross Tonnage (GT)" sectionSubtitle="Included in the above Powerboat Experience"></resumeSectionGrossTonnage>

        </div>

        <div class="tab-pane fade show" id="cr-res-vessels" role="tabpanel" aria-labelledby="cr-res-vessels">

          <resumeSectionVessels v-bind:sectionData="resume.vessels" v-bind:for-print="false"></resumeSectionVessels>

        </div>

        <div class="tab-pane fade show" id="cr-res-trips" role="tabpanel" aria-labelledby="cr-res-trips">

          <resumeSectionExtendedTrips v-bind:sectionData="resume.extendedTrips"></resumeSectionExtendedTrips>

        </div>

        <div class="tab-pane fade show" id="cr-res-opsexp" role="tabpanel" aria-labelledby="cr-res-opsexp">

          <resumeSectionOperationalExperience v-bind:sectionData="resume.operationalExperience" sectionTitle="Operational Experience" sectionSubtitle="All operational experience is self reported and may need to be verified directly with the sailor."></resumeSectionOperationalExperience>

        </div>

        <div class="tab-pane fade show" id="cr-res-credentials" role="tabpanel" aria-labelledby="cr-res-credentials">

          <resumeSectionCredentials v-bind:sectionData="resume.credentials"></resumeSectionCredentials>

        </div>

      </div>

    </div>

    <div v-if="!lookingUpResume">
      <hr style="margin:0px;">
      <div style="font-size:10px;padding:10px;color:#888">@ Copyright 2021-{{copyYear}}, Charter Rode. Weigh anchor. Explore the world. <a href="https://www.charterrode.com" style="color:#888;font-weight:bold;text-decoration: none;" target="_blank">Charter Rode</a> is building the world's first blockchain for your sailing experience. | <a href="https://www.charterrode.com/terms-of-use/" style="color:#888;font-weight:bold;text-decoration: none;" target="_blank">Terms of Use</a> | <a href="https://www.charterrode.com/privacy-policy/" style="color:#888;font-weight:bold;text-decoration: none;" target="_blank">Privacy Policy</a></div>
    </div>
  </div>
</template>

<script>
import api from "../api/api"
import resumeSectionStandard from "../components/resume/ResumeSectionStandard"
import resumeSectionGrossTonnage from "../components/resume/ResumeSectionGrossTonnage"
import resumeSectionHullType from "../components/resume/ResumeSectionHullType"
import resumeSectionLocationType from "../components/resume/ResumeSectionLocationType"
import resumeSectionBoatOwnership from "../components/resume/ResumeSectionBoatOwnership"
import resumeSectionOperationalExperience from "../components/resume/ResumeSectionOperationalExperience"
import resumeSectionVessels from "../components/resume/ResumeSectionVessels.vue"
import resumeSectionExtendedTrips from "../components/resume/ResumeSectionExtendedTrips"
import resumeSectionCredentials from "../components/resume/ResumeSectionCredentials"
// import resumeSectionAdditionalEducation from "../../components/resume/ResumeSectionAdditionalEducation"
import { useRoute } from 'vue-router'

export default {
  name: 'Resume',
  setup() {

  },
  data() {
    return {
      copyYear: new Date().getFullYear(),
      sailorId: '',
      sailorName: '',
      noEntriesFound: false,
      lookingUpResume: false,
      resumeUrl: undefined,
      resumePrintUrl: undefined,
      resume: {
        qualifyingDaysMap: {},
        summary: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },

        },
        sailboat: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },

        },
        powerboat: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },

        },
        vessels: [],
        extendedTrips: undefined,
        operationalExperience: {
          captain: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            }
          },
          crew: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0,
              },
              large: {
                experience: 0
              }
            }
          },
          total: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            }
          },
        },
        credentials: [],
        featuredCredentials: [],
        additionalEducation: undefined
      }
    }
  },
  mounted() {
    let self = this
    const route = useRoute()
    this.sailorId = route.params.sailorId
    this.sailorName = this.sailorId
    // check to see if we have already pulled it this session, i.e. in the localStorage,
    // if not then go ahead and ask the server and re-process the results.....
    self.lookingUpResume = true
    if ( !this.$store.state.uiDropdowns || this.$store.state.uiDropdowns.length <= 0 ) {
      // now get the drop downs
      api.getUIDropdowns().then(uiDropdowns => {
        if (uiDropdowns) {
          this.$store.commit('setUiDropdowns', uiDropdowns)
        }
        api.getResumeFor(this.sailorId).then(rawTxs => {
          self.processResume(rawTxs)
        })
      }).catch(error => {
        console.log(error)
        this.$router.push('/')
      })
    }
    else {
      api.getResumeFor(this.sailorId).then(rawTxs => {
        self.processResume(rawTxs)
      })
    }
  },
  methods: {
    featuredCredentials(theCreds) {

      let finalCreds = []
      // run through these checking to see if we have more than one USCG and if we do pick the highest priority one
      // to display....
      let uscgCreds = []
      theCreds.forEach(function(theCred) {
        if ( theCred.issuingAuthorityId === '4342bb28-c721-436b-beb3-f034b2205846' ) {
          uscgCreds.push(theCred)
        } else {
          finalCreds.push(theCred)
        }
      })

      if ( uscgCreds && uscgCreds.length > 0 ) {
        // easy case...
        if ( uscgCreds.length === 1 ) {
          finalCreds.push(uscgCreds[0])
        } else {
          // have to figure out which one to show
          uscgCreds.sort(function(a,b) {
            let aStr = a.credentialTitle
            let bStr = b.credentialTitle

            if ( aStr.indexOf('USCG OUPV') >= 0 ) {
              if ( bStr.indexOf('USCG OUPV') >= 0 ) {
                if (aStr > bStr) return 1
                if (aStr < bStr) return -1
                return 0
              } else {
                // a has it so it should be smaller
                return -1
              }
            } else if ( bStr.indexOf('USCG OUPV') >= 0 ) {
              // b has it so it should be bigger
              return 1
            } else {
              if (aStr > bStr) return 1
              if (aStr < bStr) return -1
              return 0
            }
          })
          // push the last one in the list should be be the most relevant....
          finalCreds.push(uscgCreds[uscgCreds.length-1])
        }
      }

      let theFeatureText = ", including"
      if ( finalCreds && finalCreds.length > 0 ) {
        let first = true
        finalCreds.forEach(function(theCred) {
          if (first) {
            first = false
          } else {
            theFeatureText += ','
          }
          theFeatureText += ' <strong>'+theCred.credentialTitle+'</strong>'
        })
        theFeatureText += '.'
      }
      return theFeatureText
    },
    formatNumber(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseInt(theNumber).toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    formatDecimal(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseFloat(theNumber).toLocaleString(undefined, { minimumFractionDigits: 2 })
    },
    formatHours(theNumber) {
      if ( theNumber == undefined ) { return 0}
      return parseFloat(theNumber).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
    },
    processResume(data) {
      let rawTxs = undefined
      if ( data && data.txs ) {
        rawTxs = data.txs
      }
      if ( !rawTxs || rawTxs.length <= 0 ) {
        this.noEntriesFound = true
        this.lookingUpResume = false
        return
      }
      // this.resumeUrl = 'https://www.charterrode.com/resume/'+this.sailorId
      // this.resumePrintUrl = 'https://www.charterrode.com/resume/print/'+this.sailorId
      this.resumeUrl = 'https://sailingresume.io/#'+this.sailorId
      this.resumePrintUrl = 'https://sailingresume.io/#print/'+this.sailorId
      // clear out resume
      this.resume = {
        qualifyingDaysMap: {},
        summary: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },

        },
        sailboat: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },

        },
        powerboat: {
          header: {
            totalDays: 0,
            totalNights: 0,
            totalDaysExperienceToAdd: 0,
            totalNauticalMiles: 0,
            totalHours: 0,
            totalEntries: 0
          },
          totalBoating: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            }
          },
          grossTonnage: {
            captain: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              pastYear: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              past_two_years: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastThreeYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastFiveYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              pastTenYears: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              lifetime: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                xLarge: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          locationType: {
            captain: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              inlandLake: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              greatLakes: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              nearCoastal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              offshore: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },
          hullType: {
            captain: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            crew: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
            total: {
              monohull: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              catamaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              },
              trimaran: {
                small: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                  verified: {
                    percent: '',
                    crewmate: 0,
                    charterCompany: 0
                  }
                }
              }
            },
          },
          boatOwnership: {
            captain: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            crew: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
            total: {
              self: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              friend: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              charterCompany: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              commercial: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              },
              canal: {
                small: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                medium: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                },
                large: {
                  days: 0,
                      verified: {
                    percent: '',
                        crewmate: 0,
                        charterCompany: 0
                  }
                }
              }
            },
          },

        },
        vessels: [],
        extendedTrips: undefined,
        operationalExperience: {
          captain: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            }
          },
          crew: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0,
              },
              large: {
                experience: 0
              }
            }
          },
          total: {
            dockingMarina: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            dayAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightAnchoring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            mediterraneanMooring: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            twentyFourHourCharterPeriods: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            },
            overnightSailing: {
              small: {
                experience: 0
              },
              medium: {
                experience: 0
              },
              large: {
                experience: 0
              }
            }
          },
        },
        credentials: [],
        featuredCredentials: [],
        additionalEducation: undefined
      }

      const self = this
      // console.log('starting:'+new Date().getTime())
      let oldestEntryDate = undefined
      let newestEntryDate = undefined

      let oldestEntryDateSailboat = undefined
      let newestEntryDateSailboat = undefined

      let oldestEntryDatePowerboat = undefined
      let newestEntryDatePowerboat = undefined

      // pre-process qualifying days
      self.calculateQualifyingDaysFor(rawTxs)

      rawTxs.forEach(function(aTx) {

        if ( aTx.input.log.trip ) {
          // convert trip to usable record
          let flatTrip = self.convertTripToFlatRecord(aTx)

          // which of the timeframe buckets does it apply?
          flatTrip = self.applyTimeFrameBucketTo(flatTrip)

          if ( !oldestEntryDate || oldestEntryDate > flatTrip.entryDate ) {
            oldestEntryDate = flatTrip.entryDate
          }
          if ( !newestEntryDate || newestEntryDate < flatTrip.entryDate ) {
            if ( flatTrip.multiDayTrip ) {
              newestEntryDate = flatTrip.entryEndDate
            } else {
              newestEntryDate = flatTrip.entryDate
            }
          }

          self.resume.summary.header.totalEntries += 1
          self.resume.summary.header.totalDaysExperienceToAdd += flatTrip.totalDaysExperienceToAdd
          self.resume.summary.header.totalDays += flatTrip.totalDays
          self.resume.summary.header.totalNights += flatTrip.totalNights
          self.resume.summary.header.totalNauticalMiles += parseFloat(flatTrip.totalNauticalMiles)
          self.resume.summary.header.totalHours += parseFloat(flatTrip.totalHours)

          if (flatTrip.boatType === 'sail') {
            if (!oldestEntryDateSailboat || oldestEntryDateSailboat > flatTrip.entryDate) {
              oldestEntryDateSailboat = flatTrip.entryDate
            }
            if (!newestEntryDateSailboat || newestEntryDateSailboat < flatTrip.entryDate) {
              if ( flatTrip.multiDayTrip ) {
                newestEntryDateSailboat = flatTrip.entryEndDate
              } else {
                newestEntryDateSailboat = flatTrip.entryDate
              }
            }
            self.resume.sailboat.header.totalEntries += 1
            self.resume.sailboat.header.totalDaysExperienceToAdd += flatTrip.totalDaysExperienceToAdd
            self.resume.sailboat.header.totalDays += flatTrip.totalDays
            self.resume.sailboat.header.totalNights += flatTrip.totalNights
            self.resume.sailboat.header.totalNauticalMiles += parseFloat(flatTrip.totalNauticalMiles)
            self.resume.sailboat.header.totalHours += parseFloat(flatTrip.totalHours)
          }
          if (flatTrip.boatType === 'power') {
            if (!oldestEntryDatePowerboat || oldestEntryDatePowerboat > flatTrip.entryDate) {
              oldestEntryDatePowerboat = flatTrip.entryDate
            }
            if (!newestEntryDatePowerboat || newestEntryDatePowerboat < flatTrip.entryDate) {
              if ( flatTrip.multiDayTrip ) {
                newestEntryDatePowerboat = flatTrip.entryEndDate
              } else {
                newestEntryDatePowerboat = flatTrip.entryDate
              }
            }
            self.resume.powerboat.header.totalEntries += 1
            self.resume.powerboat.header.totalDaysExperienceToAdd += flatTrip.totalDaysExperienceToAdd
            self.resume.powerboat.header.totalDays += flatTrip.totalDays
            self.resume.powerboat.header.totalNights += flatTrip.totalNights
            self.resume.powerboat.header.totalNauticalMiles += parseFloat(flatTrip.totalNauticalMiles)
            self.resume.powerboat.header.totalHours += parseFloat(flatTrip.totalHours)
          }

          if ( flatTrip.hasResumeQualifyingExperience ) {
            // now aggregate into the applicable experience sections....
            // summary tab
            // total boating experience
            if (flatTrip.skipper || flatTrip.captain) {
              if (flatTrip.lifetime) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'captain', 'lifetime')
              }
              if (flatTrip.tenYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'captain', 'pastTenYears')
              }
              if (flatTrip.fiveYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'captain', 'pastFiveYears')
              }
              if (flatTrip.threeYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'captain', 'pastThreeYears')
              }
              if (flatTrip.pastYear) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'captain', 'pastYear')
              }
            } else if (flatTrip.crew) {
              if (flatTrip.lifetime) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'crew', 'lifetime')
              }
              if (flatTrip.tenYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'crew', 'pastTenYears')
              }
              if (flatTrip.fiveYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'crew', 'pastFiveYears')
              }
              if (flatTrip.threeYears) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'crew', 'pastThreeYears')
              }
              if (flatTrip.pastYear) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'totalBoating', 'crew', 'pastYear')
              }
            }

            // total boating by tonnage
            if (flatTrip.skipper || flatTrip.captain) {
              if (flatTrip.lifetime) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'captain', 'lifetime')
              }
              if (flatTrip.tenYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'captain', 'pastTenYears')
              }
              if (flatTrip.fiveYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'captain', 'pastFiveYears')
              }
              if (flatTrip.threeYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'captain', 'pastThreeYears')
              }
              if (flatTrip.pastYear) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'captain', 'pastYear')
              }
            } else if (flatTrip.crew) {
              if (flatTrip.lifetime) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'crew', 'lifetime')
              }
              if (flatTrip.tenYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'crew', 'pastTenYears')
              }
              if (flatTrip.fiveYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'crew', 'pastFiveYears')
              }
              if (flatTrip.threeYears) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'crew', 'pastThreeYears')
              }
              if (flatTrip.pastYear) {
                self.processFlatTripForSectionByVesselTonnage(flatTrip, 'summary', 'grossTonnage', 'crew', 'pastYear')
              }
            }
            // total by location type
            if (flatTrip.skipper || flatTrip.captain) {
              if (flatTrip.locationType === 'inland') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'captain', 'inlandLake')
              } else if (flatTrip.locationType === 'great_lakes') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'captain', 'greatLakes')
              } else if (flatTrip.locationType === 'near_coastal') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'captain', 'nearCoastal')
              } else if (flatTrip.locationType === 'offshore') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'captain', 'offshore')
              } else if (flatTrip.locationType === 'canal') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'captain', 'canal')
              }
            } else if (flatTrip.crew) {
              if (flatTrip.locationType === 'inland') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'crew', 'inlandLake')
              } else if (flatTrip.locationType === 'great_lakes') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'crew', 'greatLakes')
              } else if (flatTrip.locationType === 'near_coastal') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'crew', 'nearCoastal')
              } else if (flatTrip.locationType === 'offshore') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'crew', 'offshore')
              } else if (flatTrip.locationType === 'canal') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'locationType', 'crew', 'canal')
              }
            }
            // total by hull type
            if (flatTrip.skipper || flatTrip.captain) {
              if (flatTrip.numberHulls === 1) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'captain', 'monohull')
              } else if (flatTrip.numberHulls === 2) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'captain', 'catamaran')
              } else if (flatTrip.numberHulls === 3) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'captain', 'trimaran')
              }
            } else if (flatTrip.crew) {
              if (flatTrip.numberHulls === 1) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'crew', 'monohull')
              } else if (flatTrip.numberHulls === 2) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'crew', 'catamaran')
              } else if (flatTrip.numberHulls === 3) {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'hullType', 'crew', 'trimaran')
              }
            }
            // boat ownership type
            if (flatTrip.skipper || flatTrip.captain) {
              if (flatTrip.boatOwner === 'self') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'captain', 'self')
              } else if (flatTrip.boatOwner === 'friend') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'captain', 'friend')
              } else if (flatTrip.boatOwner === 'charterCompany') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'captain', 'charterCompany')
              } else if (flatTrip.boatOwner === 'commercial') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'captain', 'commercial')
              }
            } else if (flatTrip.crew) {
              if (flatTrip.boatOwner === 'self') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'crew', 'self')
              } else if (flatTrip.boatOwner === 'friend') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'crew', 'friend')
              } else if (flatTrip.boatOwner === 'charterCompany') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'crew', 'charterCompany')
              } else if (flatTrip.boatOwner === 'commercial') {
                self.processFlatTripForSectionByVesselLength(flatTrip, 'summary', 'boatOwnership', 'crew', 'commercial')
              }
            }

            // sailboat experience
            if (flatTrip.boatType === 'sail') {
              // total experience
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'captain', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'captain', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'captain', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'captain', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'captain', 'pastYear')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'crew', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'crew', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'crew', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'crew', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'totalBoating', 'crew', 'pastYear')
                }
              }
              // gross tonnage
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'captain', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'captain', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'captain', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'captain', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'captain', 'pastYear')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'crew', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'crew', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'crew', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'crew', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'sailboat', 'grossTonnage', 'crew', 'pastYear')
                }
              }
              // location type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.locationType === 'inland') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'captain', 'inlandLake')
                } else if (flatTrip.locationType === 'great_lakes') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'captain', 'greatLakes')
                } else if (flatTrip.locationType === 'near_coastal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'captain', 'nearCoastal')
                } else if (flatTrip.locationType === 'offshore') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'captain', 'offshore')
                } else if (flatTrip.locationType === 'canal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'captain', 'canal')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.locationType === 'inland') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'crew', 'inlandLake')
                } else if (flatTrip.locationType === 'great_lakes') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'crew', 'greatLakes')
                } else if (flatTrip.locationType === 'near_coastal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'crew', 'nearCoastal')
                } else if (flatTrip.locationType === 'offshore') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'crew', 'offshore')
                } else if (flatTrip.locationType === 'canal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'locationType', 'crew', 'canal')
                }
              }
              // hull type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.numberHulls === 1) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'captain', 'monohull')
                } else if (flatTrip.numberHulls === 2) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'captain', 'catamaran')
                } else if (flatTrip.numberHulls === 3) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'captain', 'trimaran')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.numberHulls === 1) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'crew', 'monohull')
                } else if (flatTrip.numberHulls === 2) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'crew', 'catamaran')
                } else if (flatTrip.numberHulls === 3) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'hullType', 'crew', 'trimaran')
                }
              }
              // boat ownership type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.boatOwner === 'self') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'captain', 'self')
                } else if (flatTrip.boatOwner === 'friend') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'captain', 'friend')
                } else if (flatTrip.boatOwner === 'charterCompany') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'captain', 'charterCompany')
                } else if (flatTrip.boatOwner === 'commercial') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'captain', 'commercial')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.boatOwner === 'self') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'crew', 'self')
                } else if (flatTrip.boatOwner === 'friend') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'crew', 'friend')
                } else if (flatTrip.boatOwner === 'charterCompany') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'crew', 'charterCompany')
                } else if (flatTrip.boatOwner === 'commercial') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'sailboat', 'boatOwnership', 'crew', 'commercial')
                }
              }
            }

            // powerboat experience
            if (flatTrip.boatType === 'power') {
              // total power
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'captain', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'captain', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'captain', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'captain', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'captain', 'pastYear')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'crew', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'crew', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'crew', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'crew', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'totalBoating', 'crew', 'pastYear')
                }
              }
              // gross tonnage
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'captain', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'captain', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'captain', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'captain', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'captain', 'pastYear')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.lifetime) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'crew', 'lifetime')
                }
                if (flatTrip.tenYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'crew', 'pastTenYears')
                }
                if (flatTrip.fiveYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'crew', 'pastFiveYears')
                }
                if (flatTrip.threeYears) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'crew', 'pastThreeYears')
                }
                if (flatTrip.pastYear) {
                  self.processFlatTripForSectionByVesselTonnage(flatTrip, 'powerboat', 'grossTonnage', 'crew', 'pastYear')
                }
              }
              // location type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.locationType === 'inland') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'captain', 'inlandLake')
                } else if (flatTrip.locationType === 'great_lakes') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'captain', 'greatLakes')
                } else if (flatTrip.locationType === 'near_coastal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'captain', 'nearCoastal')
                } else if (flatTrip.locationType === 'offshore') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'captain', 'offshore')
                } else if (flatTrip.locationType === 'canal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'captain', 'canal')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.locationType === 'inland') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'crew', 'inlandLake')
                } else if (flatTrip.locationType === 'great_lakes') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'crew', 'greatLakes')
                } else if (flatTrip.locationType === 'near_coastal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'crew', 'nearCoastal')
                } else if (flatTrip.locationType === 'offshore') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'crew', 'offshore')
                } else if (flatTrip.locationType === 'canal') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'locationType', 'crew', 'canal')
                }
              }
              // hull type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.numberHulls === 1) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'captain', 'monohull')
                } else if (flatTrip.numberHulls === 2) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'captain', 'catamaran')
                } else if (flatTrip.numberHulls === 3) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'captain', 'trimaran')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.numberHulls === 1) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'crew', 'monohull')
                } else if (flatTrip.numberHulls === 2) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'crew', 'catamaran')
                } else if (flatTrip.numberHulls === 3) {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'hullType', 'crew', 'trimaran')
                }
              }
              // boat ownership type
              if (flatTrip.skipper || flatTrip.captain) {
                if (flatTrip.boatOwner === 'self') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'captain', 'self')
                } else if (flatTrip.boatOwner === 'friend') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'captain', 'friend')
                } else if (flatTrip.boatOwner === 'charterCompany') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'captain', 'charterCompany')
                } else if (flatTrip.boatOwner === 'commercial') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'captain', 'commercial')
                }
              } else if (flatTrip.crew) {
                if (flatTrip.boatOwner === 'self') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'crew', 'self')
                } else if (flatTrip.boatOwner === 'friend') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'crew', 'friend')
                } else if (flatTrip.boatOwner === 'charterCompany') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'crew', 'charterCompany')
                } else if (flatTrip.boatOwner === 'commercial') {
                  self.processFlatTripForSectionByVesselLength(flatTrip, 'powerboat', 'boatOwnership', 'crew', 'commercial')
                }
              }
            }
          }

          // now process extended trips
          if ( flatTrip.multiDayTrip ) {
            if ( !self.resume.extendedTrips ) {
              self.resume.extendedTrips = []
            }
            self.resume.extendedTrips.push(flatTrip)
          }

          // and finally do the vessel section
          let vesselData = undefined
          for(let i = 0; i < self.resume.vessels.length; ++i ) {
            if ( self.resume.vessels[i].vesselInfo.vesselId == flatTrip.vessel.vesselId ) {
              vesselData = self.resume.vessels[i]
              break;
            }
          }
          if ( vesselData == undefined ) {
            // need to create it
            vesselData = {
              showDetails: false,
              vesselInfo: {
                vesselId: flatTrip.vessel.vesselId,
                ownerType: flatTrip.vessel.ownerType,
                boatOwner: flatTrip.vessel.boatOwner,
                charterCompanyName: flatTrip.vessel.charterCompanyName,
                charterBase: flatTrip.vessel.charterBase,
                commercialOwnerName: flatTrip.vessel.vesselOwnerName,
                commercialVesselFlag: flatTrip.vessel.vesselFlag,
                vesselName: flatTrip.vessel.vesselName,
                year: flatTrip.vessel.year,
                manufacturer: flatTrip.vessel.manufacturer,
                model: flatTrip.vessel.model,
                registrationInfo: flatTrip.vessel.registrationInfo,
                grossTons: flatTrip.vessel.grossTons,
                displacementTons: flatTrip.vessel.displacementTons,
                overallLengthMeter: flatTrip.vessel.overallLengthMeter,
                waterLineLengthMeter: flatTrip.vessel.waterLineLengthMeter,
                beamMeter: flatTrip.vessel.beamMeter,
                draftMeter: flatTrip.vessel.draftMeter,
                hulls: flatTrip.vessel.hulls,
                propulsion: flatTrip.vessel.propulsion
              },
              summary: {
                totalDays: 0,
                totalNights: 0,
                totalQualifyingDays: 0,
                totalNauticalMiles: 0,
                totalHours: 0,
                totalEntries: 0
              },
              vesselLength: {
                captain: {
                  pastYear: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastThreeYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastFiveYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastTenYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  lifetime:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                },
                crew: {
                  pastYear: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastThreeYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastFiveYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastTenYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  lifetime:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                },
                total: {
                  pastYear: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastThreeYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastFiveYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  pastTenYears:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  lifetime:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                }
              },
              locationType: {
                captain: {
                  inlandLake: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  greatLakes:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  nearCoastal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  offshore:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  canal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                },
                crew: {
                  inlandLake: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  greatLakes:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  nearCoastal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  offshore:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  canal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                },
                total: {
                  inlandLake: {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  greatLakes:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  nearCoastal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  offshore:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  },
                  canal:  {
                    days: 0,
                    verified: {
                      percent: '',
                      crewmate: 0,
                      charterCompany: 0
                    }
                  }
                }
              }
            }
            self.resume.vessels.push(vesselData)
          } else {
            // try to update if the type is a charter company?
            if ( vesselData.vesselInfo.charterCompanyName === undefined ) {
              if ( flatTrip.vessel.charterCompanyName ) {
                vesselData.vesselInfo.charterCompanyName = flatTrip.vessel.charterCompanyName
              }

            }
            if ( vesselData.vesselInfo.charterBase === undefined ) {
              if ( flatTrip.vessel.charterBase ) {
                vesselData.vesselInfo.charterBase = flatTrip.vessel.charterBase
              }
            }
            if ( vesselData.vesselInfo.registrationInfo === undefined ) {
              if ( flatTrip.vessel.registrationInfo ) {
                vesselData.vesselInfo.registrationInfo = flatTrip.vessel.registrationInfo
              }
            }
            if ( vesselData.vesselInfo.commercialOwnerName === undefined ) {
              if ( flatTrip.vessel.commercialOwnerName ) {
                vesselData.vesselInfo.commercialOwnerName = flatTrip.vessel.commercialOwnerName
              }
            }
            if ( vesselData.vesselInfo.commercialVesselFlag === undefined ) {
              if ( flatTrip.vessel.commercialVesselFlag ) {
                vesselData.vesselInfo.commercialVesselFlag = flatTrip.vessel.commercialVesselFlag
              }
            }
          }
          // now we can add the flatTrip info to the vesselData!
          if ( !vesselData.summary.oldestEntryDate || vesselData.summary.oldestEntryDate > flatTrip.entryDate ) {
            vesselData.summary.oldestEntryDate = flatTrip.entryDate
          }
          if ( !vesselData.summary.newestEntryDate || vesselData.summary.newestEntryDate < flatTrip.entryDate ) {
            vesselData.summary.newestEntryDate = flatTrip.entryDate
          }

          vesselData.summary.totalEntries += 1
          vesselData.summary.totalQualifyingDays += flatTrip.totalDaysExperienceToAdd
          vesselData.summary.totalDays += flatTrip.totalDays
          vesselData.summary.totalNights += flatTrip.totalNights
          vesselData.summary.totalNauticalMiles += parseFloat(flatTrip.totalNauticalMiles)
          vesselData.summary.totalHours += parseFloat(flatTrip.totalHours)

          // process qualifying days overall
          if (flatTrip.skipper || flatTrip.captain) {
            if (flatTrip.lifetime) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','captain', 'lifetime')
            }
            if (flatTrip.tenYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','captain', 'pastTenYears')
            }
            if (flatTrip.fiveYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','captain', 'pastFiveYears')
            }
            if (flatTrip.threeYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','captain', 'pastThreeYears')
            }
            if (flatTrip.pastYear) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','captain', 'pastYear')
            }
          } else if (flatTrip.crew) {
            if (flatTrip.lifetime) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','crew', 'lifetime')
            }
            if (flatTrip.tenYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','crew', 'pastTenYears')
            }
            if (flatTrip.fiveYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','crew', 'pastFiveYears')
            }
            if (flatTrip.threeYears) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,'vesselLength','crew', 'pastThreeYears')
            }
            if (flatTrip.pastYear) {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'vesselLength','crew', 'pastYear')
            }
          }

          // process qualifying days by location type
          if (flatTrip.skipper || flatTrip.captain) {
            if (flatTrip.locationType === 'inland') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'locationType', 'captain', 'inlandLake')
            } else if (flatTrip.locationType === 'great_lakes') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'captain', 'greatLakes')
            } else if (flatTrip.locationType === 'near_coastal') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'captain', 'nearCoastal')
            } else if (flatTrip.locationType === 'offshore') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData, 'locationType', 'captain', 'offshore')
            } else if (flatTrip.locationType === 'canal') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'captain', 'canal')
            }
          } else if (flatTrip.crew) {
            if (flatTrip.locationType === 'inland') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'crew', 'inlandLake')
            } else if (flatTrip.locationType === 'great_lakes') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'crew', 'greatLakes')
            } else if (flatTrip.locationType === 'near_coastal') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'crew', 'nearCoastal')
            } else if (flatTrip.locationType === 'offshore') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'crew', 'offshore')
            } else if (flatTrip.locationType === 'canal') {
              self.processFlatTripForExperienceForVessel(flatTrip, vesselData,  'locationType', 'crew', 'canal')
            }
          }


        } else if ( aTx.input.log.identityVerification ) {
          // process identity verification
          // don't include identity validation as an entry for the summary
          // self.resume.summary.header.totalEntries += 1
          if ( aTx.input.log.identityVerification.verifiedDate ) {
            let theDate = aTx.input.log.identityVerification.verifiedDate
            let theDateTime = new Date(theDate+'T00:00:00'+new Date(theDate).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
            self.resume.summary.header.identityVerifiedOn = self.formatDate(theDateTime)
          } else {
            self.resume.summary.header.identityVerifiedOn = self.formatDate(aTx.time)
          }
        } else if ( aTx.input.log.credential ) {
          let theCred = {
            credentialTitle: aTx.input.log.credential.credentialTitle,
            issuedDate: aTx.input.log.credential.issuedDate,
            issuingAuthorityId: aTx.input.log.credential.issuingAuthorityId
          }

          let theDate = aTx.input.log.credential.issuedDate
          let theDateTime = new Date(theDate+'T00:00:00'+new Date(theDate).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
          theCred.issuedDate = self.formatDate(theDateTime)

          if ( aTx.input.log.credential.expireDate ) {
            let theExpDate = aTx.input.log.credential.expireDate
            let theExpDateTime = new Date(theExpDate+'T00:00:00'+new Date(theExpDate).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
            theCred.expireDate = self.formatDate(theExpDateTime)
          }
          if ( aTx.input.verificationAuthority.type === 'charter_rode') {
            theCred.verifiedBy = 'Charter Rode'
          } else if ( aTx.input.verificationAuthority.verificationAuthorityId === aTx.input.log.credential.issuingAuthorityId ) {
            // need to figure out how to get the name of the issuing authority here!
            theCred.verifiedBy = 'Issuing Authority'
          }

          if ( aTx.input.log.credential.endorsements && aTx.input.log.credential.endorsements.length > 0 ) {
            theCred.endorsements = aTx.input.log.credential.endorsements
          }
          self.resume.credentials.push(theCred)
          if ( theCred.issuingAuthorityId === '4342bb28-c721-436b-beb3-f034b2205846' ) {
            // this is a featured credential from USCG
            // has it expired?
            if ( theCred.expireDate && new Date(theCred.expireDate).getTime() < new Date().getTime() ) {
              console.log('featured credential is expired')
            } else {
              self.resume.featuredCredentials.push(theCred)
            }
          }
        } else if ( aTx.input.log.operationalExperience ) {
          // process some operational experience!
          let flatOpEx = self.convertOperationalExperienceToFlatRecord(aTx)
          // boat ownership type
          if (flatOpEx.skipper || flatOpEx.captain) {
            if (flatOpEx.dayAnchoring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'dayAnchoring', flatOpEx.dayAnchoring)
            }
            if (flatOpEx.overnightAnchoring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'overnightAnchoring', flatOpEx.overnightAnchoring)
            }
            if (flatOpEx.mediterraneanMooring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'mediterraneanMooring', flatOpEx.mediterraneanMooring)
            }
            if (flatOpEx.dockingMarina  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'dockingMarina', flatOpEx.dockingMarina)
            }
            if (flatOpEx.overnightSailing  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'overnightSailing', flatOpEx.overnightSailing)
            }
            if (flatOpEx.twentyFourHourCharterPeriods  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'captain', 'twentyFourHourCharterPeriods', flatOpEx.twentyFourHourCharterPeriods)
            }
          } else if (flatOpEx.crew) {
            if (flatOpEx.dayAnchoring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'dayAnchoring', flatOpEx.dayAnchoring)
            }
            if (flatOpEx.overnightAnchoring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'overnightAnchoring', flatOpEx.overnightAnchoring)
            }
            if (flatOpEx.mediterraneanMooring > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'mediterraneanMooring', flatOpEx.mediterraneanMooring)
            }
            if (flatOpEx.dockingMarina  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'dockingMarina', flatOpEx.dockingMarina)
            }
            if (flatOpEx.overnightSailing  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'overnightSailing', flatOpEx.overnightSailing)
            }
            if (flatOpEx.twentyFourHourCharterPeriods  > 0 ) {
              self.processOperationalExperienceByVesselLength(flatOpEx, 'operationalExperience', 'crew', 'twentyFourHourCharterPeriods', flatOpEx.twentyFourHourCharterPeriods)
            }
          }
        }
      })

      if ( oldestEntryDate && newestEntryDate) {
        self.resume.summary.header.oldestEntryDate = self.formatDate(oldestEntryDate)
        self.resume.summary.header.newestEntryDate = self.formatDate(newestEntryDate)
      }

      if ( oldestEntryDateSailboat && newestEntryDateSailboat) {
        self.resume.sailboat.header.oldestEntryDate = self.formatDate(oldestEntryDateSailboat)
        self.resume.sailboat.header.newestEntryDate = self.formatDate(newestEntryDateSailboat)
      }

      if ( oldestEntryDatePowerboat && newestEntryDatePowerboat) {
        self.resume.powerboat.header.oldestEntryDate = self.formatDate(oldestEntryDatePowerboat)
        self.resume.powerboat.header.newestEntryDate = self.formatDate(newestEntryDatePowerboat)
      }

      if ( self.resume.credentials ) {
        // provide some sorting guidance
        self.resume.credentials.sort(function (a, b) {
          if (a.credentialTitle > b.credentialTitle) return 1
          if (a.credentialTitle < b.credentialTitle) return -1
          return 0
        })
      }

      self.resume.summary.header.totalNauticalMiles = parseFloat(self.resume.summary.header.totalNauticalMiles).toFixed(2)
      self.resume.summary.header.totalHours = parseFloat(self.resume.summary.header.totalHours).toFixed(2)

      // console.log('ending:'+new Date().getTime())
      // console.log(JSON.stringify(self.resume))

      // sort the trips in reverse chronological order
      if ( self.resume.extendedTrips ) {
        self.resume.extendedTrips.sort(function (a, b) {
          let aDateInt = parseInt(a.startDate.replaceAll('-', ''))
          let bDateInt = parseInt(b.startDate.replaceAll('-', ''))
          if (aDateInt == bDateInt) {
            return 0
          } else if (aDateInt > bDateInt) {
            return -1
          } else {
            return 1
          }
        })
      }

      // figure out percentage of total qualifying experience for each vessel
      self.resume.vessels.forEach(function(aVessel) {
          if ( aVessel.summary.totalQualifyingDays && self.resume.summary.header.totalDaysExperienceToAdd ) {
            aVessel.summary.percentQualifyingDays = aVessel.summary.totalQualifyingDays / self.resume.summary.header.totalDaysExperienceToAdd
          }
      })
      // sort self.resume.vessels by vessel ownership and vessel length (self, longest first)
      if ( self.resume.vessels ) {
        self.resume.vessels.sort(function (a, b) {
          let aPercentQualifyingDays = a.summary.percentQualifyingDays
          let bPercentQualifyingDays = b.summary.percentQualifyingDays
          if ( aPercentQualifyingDays !== undefined && bPercentQualifyingDays !== undefined ) {
            if ( aPercentQualifyingDays > bPercentQualifyingDays ) {
              return -1
            } else if ( aPercentQualifyingDays < bPercentQualifyingDays ) {
              return 1
            } else {
              return 0
            }
          } else {
            if ( aPercentQualifyingDays !== undefined && bPercentQualifyingDays === undefined ) {
              return -1
            } else if ( aPercentQualifyingDays === undefined && bPercentQualifyingDays !== undefined ) {
              return 1
            } else {
              // try most recent experience first
              if ( a.summary.newestEntryDate && b.summary.newestEntryDate ) {
                let aNewestEntryDate = a.summary.newestEntryDate
                let bNewestEntryDate = b.summary.newestEntryDate
                if ( aNewestEntryDate > bNewestEntryDate ) {
                  return -1
                } else if ( aNewestEntryDate < bNewestEntryDate ) {
                  return 1
                }
              }
              // then longest first!
              let aOverallVesselLength = a.vesselInfo.overallLengthMeter
              let bOverallVesselLength = b.vesselInfo.overallLengthMeter
              if (aOverallVesselLength == bOverallVesselLength) {
                return 0
              } else if (aOverallVesselLength > bOverallVesselLength) {
                return -1
              } else {
                return 1
              }
            }
          }
          // let aBoatOwnerType = a.vesselInfo.boatOwner
          // let bBoatOwnerType = b.vesselInfo.boatOwner
          // let aOverallVesselLength = a.vesselInfo.overallLengthMeter
          // let bOverallVesselLength = b.vesselInfo.overallLengthMeter
          // if (aBoatOwnerType == bBoatOwnerType) {
          //   if (aOverallVesselLength == bOverallVesselLength) {
          //     return 0
          //   } else if (aOverallVesselLength > bOverallVesselLength) {
          //     return -1
          //   } else {
          //     return 1
          //   }
          // } else if (aBoatOwnerType === 'self') {
          //   return -1
          // } else if (bBoatOwnerType === 'self' ) {
          //   return 1
          // } else if (aBoatOwnerType === 'friend' ) {
          //   return -1
          // } else if (bBoatOwnerType === 'friend' ) {
          //   return 1
          // } else if (aBoatOwnerType === 'charterCompany' ) {
          //   return -1
          // } else if (bBoatOwnerType === 'charterCompany' ) {
          //   return 1
          // } else if (aBoatOwnerType === 'commercial' ) {
          //   return -1
          // } else if (bBoatOwnerType === 'commercial' ) {
          //   return 1
          // } else {
          //   return 0
          // }
        })
      }

      self.lookingUpResume = false

      if ( data.sailorFullName && data.sailorFullName.trim().length > 0 ) {
        window.document.title = 'Trusted Sailing Resume for '+data.sailorFullName
        self.sailorName = data.sailorFullName
      } else {
        window.document.title = 'Trusted Sailing Resume for '+this.sailorId
      }
    },
    processFlatTripForSectionByVesselLength(flatTrip, aSection, aSubSection, captainOrCrew, aTimeWindow) {
      let self = this
      let totalDaysExperienceToAdd = flatTrip.totalDaysExperienceToAdd
      if (flatTrip.vesselLength < 8.5) {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].small.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        }
      } else if (flatTrip.vesselLength < 12.0) {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].medium.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        }
      } else {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].large.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        }
      }
    },
    processFlatTripForSectionByVesselTonnage(flatTrip, aSection, aSubSection, captainOrCrew, aTimeWindow) {
      let self = this
      let totalDaysExperienceToAdd = flatTrip.totalDaysExperienceToAdd
      if (flatTrip.grossTons <= 25) {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].small.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].small.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].small.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].small.days)
        }
      } else if (flatTrip.grossTons <= 50) {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].medium.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].medium.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].medium.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].medium.days)
        }
      } else if (flatTrip.grossTons <= 100) {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].large.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].large.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].large.days)
        }
      } else {
        self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.days += totalDaysExperienceToAdd
        self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days += totalDaysExperienceToAdd
        if (flatTrip.verificationType === 'crewmate') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.crewmate += (flatTrip.totalDays + flatTrip.totalNights)
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.crewmate += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days)
        } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.charterCompany += totalDaysExperienceToAdd
          self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days)
        } else {
          self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection][captainOrCrew][aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days)
          self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.percent = self.calculatePercentFormatted((self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.crewmate + self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.verified.charterCompany), self.resume[aSection][aSubSection].total[aTimeWindow].xLarge.days)
        }
      }
    },
    processFlatTripForExperienceForVessel (flatTrip, vesselData, aSubSection, captainOrCrew, aTimeWindow) {
      let self = this
      let totalDaysExperienceToAdd = flatTrip.totalDaysExperienceToAdd
      vesselData[aSubSection][captainOrCrew][aTimeWindow].days += totalDaysExperienceToAdd
      vesselData[aSubSection].total[aTimeWindow].days += totalDaysExperienceToAdd
      if (flatTrip.verificationType === 'crewmate') {
        vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.crewmate += totalDaysExperienceToAdd
        vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.crewmate + vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.charterCompany), vesselData[aSubSection][captainOrCrew][aTimeWindow].days)
        vesselData[aSubSection].total[aTimeWindow].verified.crewmate += totalDaysExperienceToAdd
        vesselData[aSubSection].total[aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection].total[aTimeWindow].verified.crewmate + vesselData[aSubSection].total[aTimeWindow].verified.charterCompany), vesselData[aSubSection].total[aTimeWindow].days)
      } else if (flatTrip.verificationType === 'charter_rode' || flatTrip.verificationType === 'charter_company') {
        vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.charterCompany += totalDaysExperienceToAdd
        vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.crewmate + vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.charterCompany), vesselData[aSubSection][captainOrCrew][aTimeWindow].days)
        vesselData[aSubSection].total[aTimeWindow].verified.charterCompany += totalDaysExperienceToAdd
        vesselData[aSubSection].total[aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection].total[aTimeWindow].verified.crewmate + vesselData[aSubSection].total[aTimeWindow].verified.charterCompany), vesselData[aSubSection].total[aTimeWindow].days)
      } else {
        vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.crewmate + vesselData[aSubSection][captainOrCrew][aTimeWindow].verified.charterCompany), vesselData[aSubSection][captainOrCrew][aTimeWindow].days)
        vesselData[aSubSection].total[aTimeWindow].verified.percent = self.calculatePercentFormatted((vesselData[aSubSection].total[aTimeWindow].verified.crewmate + vesselData[aSubSection].total[aTimeWindow].verified.charterCompany), vesselData[aSubSection].total[aTimeWindow].days)
      }
    },
    calculatePercentFormatted(numerator, denominator) {
      if ( numerator == 0 || denominator == 0 ) {
         return '0%'
      }
      return parseFloat((parseFloat(numerator) / parseFloat(denominator) * 100)+'').toFixed(0) +'%'
    },
    applyTimeFrameBucketTo(aTrip) {

      // all are lifetime
      aTrip.lifetime = true

      // for the rest take a look at the trip.date and figure out which bucket applies
      let tenYearsAgoDate = new Date()
      tenYearsAgoDate.setFullYear(new Date().getFullYear() - 10)
      let tenYearsAgo = tenYearsAgoDate.getTime()
      let fiveYearsAgoDate = new Date()
      fiveYearsAgoDate.setFullYear(new Date().getFullYear() - 5)
      let fiveYearsAgo = fiveYearsAgoDate.getTime()
      let threeYearsAgoDate = new Date()
      threeYearsAgoDate.setFullYear(new Date().getFullYear() - 3)
      let threeYearsAgo = threeYearsAgoDate.getTime()
      let oneYearAgoDate = new Date()
      oneYearAgoDate.setFullYear(new Date().getFullYear() - 1)
      let oneYearAgo = oneYearAgoDate.getTime()

      if ( aTrip.entryDate > tenYearsAgo ) {
        aTrip.tenYears = true
      }
      if ( aTrip.entryDate > fiveYearsAgo ) {
        aTrip.fiveYears = true
      }
      if ( aTrip.entryDate > threeYearsAgo ) {
        aTrip.threeYears = true
      }
      if ( aTrip.entryDate > oneYearAgo ) {
        aTrip.pastYear = true
      }

      return aTrip
    },
    processOperationalExperienceByVesselLength(flatOpEx, aSection, captainOrCrew, aTimeWindow, totalNumberExperienceToAdd) {
      let self = this
      if (flatOpEx.vesselLength < 8.5) {
        self.resume[aSection][captainOrCrew][aTimeWindow].small.experience += totalNumberExperienceToAdd
        self.resume[aSection].total[aTimeWindow].small.experience += totalNumberExperienceToAdd
      } else if (flatOpEx.vesselLength < 12.0) {
        self.resume[aSection][captainOrCrew][aTimeWindow].medium.experience += totalNumberExperienceToAdd
        self.resume[aSection].total[aTimeWindow].medium.experience += totalNumberExperienceToAdd
      } else {
        self.resume[aSection][captainOrCrew][aTimeWindow].large.experience += totalNumberExperienceToAdd
        self.resume[aSection].total[aTimeWindow].large.experience += totalNumberExperienceToAdd
      }
    },
    convertOperationalExperienceToFlatRecord(aTx) {
      let opEx = {
        dayAnchoring: 0,
        overnightAnchoring: 0,
        mediterraneanMooring: 0,
        dockingMarina: 0,
        overnightSailing: 0,
        twentyFourHourCharterPeriods: 0
      }

      opEx.captain = aTx.input.log.operationalExperience.role === 'captain'
      opEx.skipper = aTx.input.log.operationalExperience.role === 'skipper'
      opEx.crew = aTx.input.log.operationalExperience.role === 'crew'

      if ( aTx.input.log.operationalExperience.vessel ) {
        opEx.vesselLength = parseFloat(aTx.input.log.operationalExperience.vessel.overallLengthMeter)
        opEx.boatType = aTx.input.log.operationalExperience.vessel.propulsion
        opEx.hulls = parseInt(aTx.input.log.operationalExperience.vessel.hulls)
        opEx.grossTons = parseFloat(aTx.input.log.operationalExperience.vessel.grossTons)
      } else {
        opEx.vesselLength = undefined
        opEx.boatType = undefined
        opEx.hulls = undefined
        opEx.grossTons = undefined
      }

      if ( aTx.input.log.operationalExperience.dayAnchoring ) {
        opEx.dayAnchoring += aTx.input.log.operationalExperience.dayAnchoring
      }
      if ( aTx.input.log.operationalExperience.overnightAnchoring ) {
        opEx.overnightAnchoring += aTx.input.log.operationalExperience.overnightAnchoring
      }
      if ( aTx.input.log.operationalExperience.mediterraneanMooring ) {
        opEx.mediterraneanMooring += aTx.input.log.operationalExperience.mediterraneanMooring
      }
      if ( aTx.input.log.operationalExperience.dockingMarina ) {
        opEx.dockingMarina += aTx.input.log.operationalExperience.dockingMarina
      }
      if ( aTx.input.log.operationalExperience.overnightSailing ) {
        opEx.overnightSailing += aTx.input.log.operationalExperience.overnightSailing
      }
      if ( aTx.input.log.operationalExperience.twentyFourHourCharterPeriods ) {
        opEx.twentyFourHourCharterPeriods += aTx.input.log.operationalExperience.twentyFourHourCharterPeriods
      }

      // set the date for the time frame bucketing...
      opEx.date = aTx.input.log.operationalExperience.date
      // fix this as well to be start date as a timestamp...
      opEx.entryDate = new Date(opEx.date+'T00:00:00'+new Date(opEx.date).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()

      return opEx
    },
    convertTripToFlatRecord(aTx) {
      let self = this
      let trip = {}
      trip.totalHours = 0
      trip.totalNauticalMiles = 0
      trip.totalDays = 0
      trip.totalNights = 0
      trip.totalDaysExperienceToAdd = 0
      trip.hasResumeQualifyingExperience = true
      trip.role = aTx.input.log.trip.entries[0].role
      trip.captain = aTx.input.log.trip.entries[0].role === 'captain' // consider breaking up multi-trip entries if the role is different....
      trip.skipper = aTx.input.log.trip.entries[0].role === 'skipper' // consider breaking up multi-trip entries if the role is different....
      trip.crew = aTx.input.log.trip.entries[0].role === 'crew' // consider breaking up multi-trip entries if the role is different....
      trip.locationType = aTx.input.log.trip.entries[0].locationType // consider breaking up multi-trip entries if the locationType is different....
      trip.location = aTx.input.log.trip.entries[0].location
      trip.purpose = aTx.input.log.trip.entries[0].purpose
      trip.verificationType = aTx.input.verificationAuthority.type
      trip.vessel = aTx.input.log.trip.entries[0].vessel
      if ( aTx.input.log.trip.charterCompany ) {
        trip.charterCompany = aTx.input.log.trip.charterCompany
      }
      if ( aTx.input.log.trip.entries[0].vessel.hulls ) {
        trip.numberHulls = aTx.input.log.trip.entries[0].vessel.hulls
      }
      if ( aTx.input.log.trip.entries[0].vessel.ownedBySailor ) {
        trip.boatOwner = 'self'
        trip.vesselName = aTx.input.log.trip.entries[0].vessel.vesselName
      } else if ( aTx.input.log.trip.entries[0].vessel.ownerType === 'private' ) {
        trip.boatOwner = 'friend'
        trip.vesselName = aTx.input.log.trip.entries[0].vessel.vesselName
      } else if ( aTx.input.log.trip.entries[0].vessel.ownerType === 'charter_base' ) {
        trip.boatOwner = 'charterCompany'
        trip.vesselName = aTx.input.log.trip.entries[0].vessel.vesselName
        if ( aTx.input.log.trip.entries[0].vessel.charterCompanyName ) {
          trip.chartCompanyName = aTx.input.log.trip.entries[0].vessel.charterCompanyName
          trip.vessel.charterCompanyName = trip.chartCompanyName
        }
        if ( aTx.input.log.trip.entries[0].vessel.charterBase ) {
          trip.charterBase = aTx.input.log.trip.entries[0].vessel.charterBase
          trip.vessel.charterBase = trip.charterBase
        }
      } else if ( aTx.input.log.trip.entries[0].vessel.ownerType === 'commercial' ) {
        trip.boatOwner = 'commercial'
        trip.vesselName = aTx.input.log.trip.entries[0].vessel.vesselName
      }
      trip.vessel.boatOwner = trip.boatOwner
      if ( trip.charterCompany ) {
        trip.vessel.charterCompanyName = trip.charterCompany.charterCompanyName
        trip.vessel.charterBase = trip.charterCompany.charterCompanyBaseLocation
      }
      if ( aTx.input.log.trip.entries.length > 1 ) {
        // multi-day trip
        // TODO: maybe sort the entries?
        trip.multiDayTrip = true
        trip.startDate = aTx.input.log.trip.entries[0].date
        trip.endDate = aTx.input.log.trip.entries[aTx.input.log.trip.entries.length-1].date
        trip.vesselLength = parseFloat(aTx.input.log.trip.entries[0].vessel.overallLengthMeter)
        trip.boatType = aTx.input.log.trip.entries[0].vessel.propulsion
        trip.hulls = parseInt(aTx.input.log.trip.entries[0].vessel.hulls)
        trip.grossTons = parseFloat(aTx.input.log.trip.entries[0].vessel.grossTons)

        var qualifyingDaysMap = {}
        aTx.input.log.trip.entries.forEach(function(anEntry) {
          trip.totalHours += parseFloat(anEntry.durationHours)
          trip.totalNauticalMiles += parseFloat(anEntry.nauticalMiles)
          let countedTowardsQualifyingDay = false
          if ( trip.grossTons <= 100 ) {
            if ( parseFloat(anEntry.durationHours) >= 4 ) {
              if ( anEntry.timeOfDay === 'day' ) {
                trip.totalDays += 1
                countedTowardsQualifyingDay = true
              } else if ( anEntry.timeOfDay === 'night' ) {
                trip.totalNights += 1
                countedTowardsQualifyingDay = true
              }
            }
          } else if ( trip.grossTons > 100 ) {
            if ( parseFloat(anEntry.durationHours) >= 8 ) {
              if ( anEntry.timeOfDay === 'day' ) {
                trip.totalDays += 1
                countedTowardsQualifyingDay = true
              } else if ( anEntry.timeOfDay === 'night' ) {
                trip.totalNights += 1
                countedTowardsQualifyingDay = true
              }
            }
          }

          if ( countedTowardsQualifyingDay ) {
            if (qualifyingDaysMap[anEntry.date] == undefined) {
              // check to see if they logged this date somewhere else in a different tx!
              if (self.resume.qualifyingDaysMap[anEntry.date] == undefined) {
                qualifyingDaysMap[anEntry.date] = 1
                self.resume.qualifyingDaysMap[anEntry.date] = 1
              }
            }
          }
        })
        trip.totalDaysExperienceToAdd = Object.keys(qualifyingDaysMap).length

        // set the date for the time frame bucketing...
        trip.date = trip.startDate
        trip.entryDate = new Date(trip.date+'T00:00:00'+new Date(trip.date).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
        if ( trip.multiDayTrip ) {
          trip.entryEndDate = new Date(trip.endDate+'T00:00:00'+new Date(trip.endDate).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
        }

        // go through all the entries to construct a list of unique locations visited...
        let locationsArry = new Set()
        aTx.input.log.trip.entries.forEach(function(anEntry) {
          locationsArry.add(anEntry.location)
        })
        if ( locationsArry.size > 0 ) {
          // create a string
          trip.location = Array.from(locationsArry).join(', ')
        }
      } else {
        // single day trip
        trip.multiDayTrip = false
        let anEntry = aTx.input.log.trip.entries[0]

        trip.totalHours += parseFloat(anEntry.durationHours)
        trip.totalNauticalMiles += parseFloat(anEntry.nauticalMiles)

        trip.vesselLength = parseFloat(anEntry.vessel.overallLengthMeter)
        trip.boatType = anEntry.vessel.propulsion
        trip.hulls = parseInt(anEntry.vessel.hulls)
        trip.grossTons = parseFloat(anEntry.vessel.grossTons)
        trip.vessel = aTx.input.log.trip.entries[0].vessel

        // does this entry count towards days on the water - USCG Standard
        trip.hasResumeQualifyingExperience = false
        trip.totalDays = 0
        trip.totalNights = 0
        trip.totalDaysExperienceToAdd = 0
        // see if this entry qualifies in combination of others on the same day with the same vessel....
        if ( self.resume.qualifyingDaysSummary[anEntry.date] !== undefined ) {
          // check to see if they logged this date somewhere else in a different tx!
          if ( self.resume.qualifyingDaysMap[anEntry.date] == undefined ) {
            let qualDays = self.resume.qualifyingDaysSummary[anEntry.date]
            qualDays.forEach(function (aDay) {
              if (aDay.vesselId === anEntry.vessel.vesselId && aDay.locationType === anEntry.locationType) {
                if (aDay.qualifyingDay) {
                  let countedTowardsQualifyingDay = false
                  if ( trip.grossTons <= 100 ) {
                    if ( aDay.nightHours >= 4 && anEntry.timeOfDay === 'night' ) {
                      trip.totalNights = 1
                      countedTowardsQualifyingDay = true
                    } else if ( anEntry.timeOfDay === 'day' ) {
                      trip.totalDays = 1
                      countedTowardsQualifyingDay = true
                    }
                  } else if ( trip.grossTons > 100 ) {
                    if ( aDay.nightHours >= 8 && anEntry.timeOfDay === 'night' ) {
                      trip.totalNights = 1
                      countedTowardsQualifyingDay = true
                    } else if ( anEntry.timeOfDay === 'day' ) {
                      trip.totalDays = 1
                      countedTowardsQualifyingDay = true
                    }
                  }

                  if ( countedTowardsQualifyingDay ) {
                    trip.hasResumeQualifyingExperience = true
                    trip.totalDaysExperienceToAdd = 1
                    self.resume.qualifyingDaysMap[anEntry.date] = 1
                  }
                }
              }
            })
          }
        }
        // set the date for the time frame bucketing...
        trip.date = anEntry.date
        // fix this as well to be start date as a timestamp...
        trip.entryDate = new Date(trip.date+'T00:00:00'+new Date(trip.date).toString().match(/([-\\+][0-9]+)\s/)[1]).getTime()
      }

      return trip
    },
    calculateQualifyingDaysFor(txs) {
      // there are a few edge cases where we need to know ahead of time if an entry will be
      // a qualifying day. The USCG requires 4 or more hours for <= 100 GT and 8 or more hours
      // for > 100 GT. The edge case that prompted this is when there are two logbook entries
      // for a single day on the same vessel that are both less than the required 4 hours but
      // when combined, are 4 or more hours. We are interpreting the requirement to be on the
      // same vessel vs. logging for example two 3 hour entries on two different vessels in the
      // same day.
      // the map has the following object structure:
      // {
      //    '2022-06-01': [{
      //      vesselId: "vesselId",
      //      locationType: "location_type",
      //      grossTons: 0,
      //      totalHours: 0,
      //      dayHours: 0,
      //      nightHours: 0,
      //      qualifyingDay: false
      //    }]
      let self = this
      self.resume.qualifyingDaysSummary = {}

      // run through all transactions and populate the qualifyingDaysMap
      txs.forEach(function(aTx) {
        if ( aTx && aTx.input && aTx.input.log && aTx.input.log.trip && aTx.input.log.trip.entries ) {
          // have a 'trip' logbook entry
          // let trip = aTx.input.log.trip
          aTx.input.log.trip.entries.forEach(function(anEntry) {
            let entryDate = anEntry.date
            let vesselId = anEntry.vessel.vesselId
            let grossTons = parseFloat(anEntry.vessel.grossTons)
            let totalHours = parseFloat(anEntry.durationHours)
            let locationType = anEntry.locationType
            // anEntry.timeOfDay
            let qualDay = undefined
            if ( self.resume.qualifyingDaysSummary[entryDate] !== undefined ) {
              let qualDays = self.resume.qualifyingDaysSummary[entryDate]
              qualDays.forEach(function(aDay) {
                if ( aDay.vesselId === vesselId && aDay.locationType === locationType ) {
                  qualDay = aDay
                }
              })
            } else {
              // need to create one
              qualDay = {
                vesselId: vesselId,
                locationType: locationType,
                grossTons: grossTons,
                totalHours: 0,
                dayHours: 0,
                nightHours: 0,
                qualifyingDay: false

              }
              self.resume.qualifyingDaysSummary[entryDate] = []
              self.resume.qualifyingDaysSummary[entryDate].push(qualDay)
            }

            // should have qualDay now
            if ( qualDay !== undefined ) {
              // add hours.....
              qualDay.totalHours += totalHours
              if ( anEntry.timeOfDay === 'day' ) {
                qualDay.dayHours += totalHours
              } else if ( anEntry.timeOfDay === 'night' ) {
                qualDay.nightHours += totalHours
              }

              if ( grossTons <= 100 ) {
                if ( qualDay.totalHours >= 4 ) {
                  qualDay.qualifyingDay = true
                }
              } else if ( grossTons > 100 ) {
                if ( qualDay.totalHours >= 8 ) {
                  qualDay.qualifyingDay = true
                }
              }
            }
          })
        }
      })

      // all done now so spit out to console.
      // console.log(JSON.stringify(self.resume.qualifyingDaysSummary))
    },
    formatDate(aDateInSeconds) {
      return new Date(aDateInSeconds).toLocaleDateString("en-US")
    },
    lookupResume() {
      let sailorId = prompt("Sailor ID")
      if ( sailorId && sailorId.trim().length > 0 ) {
        this.lookingUpResume = true
        api.getResumeFor(sailorId).then(rawTxs => {
          this.sailorId = sailorId
          this.processResume(rawTxs)
        })
      }
    }
  },
  components: {
    resumeSectionStandard: resumeSectionStandard,
    resumeSectionGrossTonnage: resumeSectionGrossTonnage,
    resumeSectionHullType: resumeSectionHullType,
    resumeSectionLocationType: resumeSectionLocationType,
    resumeSectionBoatOwnership: resumeSectionBoatOwnership,
    resumeSectionOperationalExperience: resumeSectionOperationalExperience,
    resumeSectionVessels: resumeSectionVessels,
    resumeSectionExtendedTrips: resumeSectionExtendedTrips,
    resumeSectionCredentials: resumeSectionCredentials
    // resumeSectionAdditionalEducation: resumeSectionAdditionalEducation
  }
}
</script>

<style>
.tx-submit-spinner {
  text-align: center;
  margin-top:5px;
  font-size:22px;
  color:#666;
  font-weight: normal;
  z-index: 9999;
}
</style>