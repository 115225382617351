<template>

  <div>
    <div class="card cr-card" style="margin:0px 30px 30px 30px;">
      <div class="card-body">
        <h5 class="card-title">{{sectionTitle}}</h5>
        <h6 class="card-subtitle mb-2 text-muted" v-if="sectionSubtitle">{{sectionSubtitle}}</h6>
        <div class="table-responsive-xxl">
          <table class="cr-resume-table table table-striped table-hover" :class="{'table-sm': forPrint}">
            <thead>
              <tr>
                <th class="cr-resume-background-none cr-resume-border-bottom-none cr-resume-border-right">&nbsp;</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Skipper</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">As Crew</th>
                <th colspan="3" class="cr-resume-border-top cr-resume-border-right fs-5 text-center">Total</th>
              </tr>
              <tr>
                <th class="cr-resume-background-none cr-resume-border-top-none cr-resume-border-right">&nbsp;</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
                <th class="text-center text-nowrap">Less than<br>8.5m (28 ft)</th>
                <th class="text-center text-nowrap">8.5m (28 ft)<br>- 12m (39 ft)</th>
                <th class="text-center text-nowrap cr-resume-border-right">Greater than<br>12m (39 ft)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-nowrap cr-resume-border-right">Past year</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastYear.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastYear.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.pastYear.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastYear.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastYear.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.pastYear.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastYear.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastYear.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.pastYear.large)"></td>
              </tr>
              <tr>
                <td class="text-nowrap cr-resume-border-right">Past 3 years</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastThreeYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastThreeYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.pastThreeYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastThreeYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastThreeYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.pastThreeYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastThreeYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastThreeYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.pastThreeYears.large)"></td>
              </tr>
              <tr>
                <td class="text-nowrap cr-resume-border-right">Past 5 years</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastFiveYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastFiveYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.pastFiveYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastFiveYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastFiveYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.pastFiveYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastFiveYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastFiveYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.pastFiveYears.large)"></td>
              </tr>
              <tr>
                <td class="text-nowrap cr-resume-border-right">Past 10 years</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastTenYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.pastTenYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.pastTenYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastTenYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.pastTenYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.pastTenYears.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastTenYears.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.pastTenYears.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.pastTenYears.large)"></td>
              </tr>
              <tr>
                <td class="text-nowrap cr-resume-border-right">Lifetime</td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.lifetime.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.captain.lifetime.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.captain.lifetime.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.lifetime.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.crew.lifetime.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.crew.lifetime.large)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.lifetime.small)"></td>
                <td class="text-center" v-html="formatResumeDaysCell(sectionData.total.lifetime.medium)"></td>
                <td class="text-center cr-resume-border-right" v-html="formatResumeDaysCell(sectionData.total.lifetime.large)"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cr-resume-footer-note">A | B% - this is logged days (at least four hours) experience with the percentage of entries that have been verified by either a crewmate on the vessel at the time or directly with the charter company via Charter Rode. The n-m below each logged experience represents how many entries have been verified by crewmate (n) or charter company (m) digital verification. Charter company verification is the highest trusted verification. Crewmate digital verification is considered to be very reliable. Self verification may need to be verified directly with the sailor.</div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'ResumeSectionStandardView',
  props: {
    'sectionTitle': String,
    'sectionSubtitle':  String,
    'sectionData': Object,
    'forPrint': Boolean
  },
  methods: {
    formatResumeDaysCell(aCell) {
      if ( aCell.days > 0 ) {
        let formattedText = '<div>'+aCell.days+'<span style="position:relative;top:-1px;font-size: 10px;color:#888;"> | '+aCell.verified.percent+'</span></div><div style="font-size: 10px;color:#888;">'+aCell.verified.crewmate+'-'+aCell.verified.charterCompany+'</div>'
        return formattedText
      } else {
        return '--'
      }
    }
  }
}
</script>

<style>
.cr-resume-table {
  font-size:12px;
  width: 100%;
}

.cr-resume-table th {
  background: #f6f6f6;
}

.cr-resume-background-none {
  background: transparent!important;
}

.cr-resume-border-top {
  border-top: 1px solid #ccc;
}
.cr-resume-border-right {
  border-right: 1px solid #ccc;
}

.cr-resume-border-top-none {
  border-top:none!important;
}
.cr-resume-border-bottom-none {
  border-bottom:none!important;
}

.cr-resume-footer-note {
  font-size: 11px;
  color:#999;
}
</style>