import axios from "axios";

const apiBase = process.env.VUE_APP_CHARTER_RODE_API_ENDPOINT

function processErrorResponse(errorDetail) {
    console.log(errorDetail)
    // alert(errorDetail.message)
}

let api = {

    getUIDropdowns: async () => {
        try {
            const response = await axios({
                method: 'get',
                url: apiBase+'/ui/dropDownsForResume'
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here?
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    },

    getResumeFor: async (sailorId) => {
        try {
            const response = await axios({
                method: 'get',
                url: apiBase+'/sailorResume/'+sailorId
            });
            if ( response.data.responseType === "SUCCESS" ) {
                return response.data.returnObject
            } else if ( response.data.responseType === "ERROR" ) {
                // hmm what to do here? - nothing as this is an okay for new user error
                processErrorResponse(response.data.errorDetail)
            }
        } catch (error) {
            console.log(error);
        }
    }
}
export default api