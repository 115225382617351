<template>
  <div class="cr-background-sail vh-100" style="overflow: auto">

    <div class="cr-background-sail-overlay vh-100" style="overflow: auto;">

      <div style="padding:25px;z-index: 100;"><a style="z-index: 100;" href="https://www.charterrode.com"><img style="z-index: 100;" class="cr-logo" src="/logoLight.png"></a></div>
      <div class="container-fluid" style="padding-top: 75px;">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12">
            <div class="form-floating mb-4">
              <input type="text" class="form-control text-center" id="sailorId" name="sailorId" v-model="sailorId" v-bind:class="{'is-valid' : validation.sailorId, 'is-invalid': validation.sailorId == false}" v-on:keyup.enter="lookupResume">
              <label class="formLabel" for="sailorId">Sailor Id</label>
              <div class="helpBlock text-center">Enter the sailor id of the resume you are wanting to lookup</div>
              <div class="invalid-feedback">Oops! Looks like we forgot something.</div>
            </div>
          </div>
        </div>

        <div class="row pb-5 mb-5">
          <div class="col-12">
            <div class="d-grid gap-2 col-xl-4 col-lg-4 col-md-8 col-sm-8 mx-auto">
              <button class="btn btn-lg btn-primary text-uppercase p-3"  style="z-index: 100;" v-on:click="lookupResume" :disabled="sailorId == undefined || sailorId.length <= 0">Lookup Resume</button>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style="color:#bfbfbf;">
            <div class="display-5" style="text-align: center;font-weight: bold;">Build Your Trusted Sailing Resume</div>
            <div class="lead" style="line-height: 34px; font-size: 24px;">Is your unorganized <a href="https://www.charterrode.com/article/sailing-resume">sailing resume</a> preventing you from achieving your sailing dreams? Elevate your sailing resume with the confidence of blockchain. Join sailors around the world who enjoy the freedom to weigh anchor and explore the world!</div>
          </div>
        </div>


        <div class="row justify-content-center mt-5">
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 text-center">
              <div style="display:inline-block;width:265px;margin:0 10px;">
                <div class="d-grid gap-2">
                  <a class="btn btn-primary text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" href="https://app.charterrode.com/sign-up">Create Account</a>
                </div>
              </div>
              <div style="display:inline-block;width:265px;margin:0 10px;">
                <div class="d-grid gap-2">
                  <a class="btn btn-outline-light text-uppercase p-3"  style="z-index: 100;margin-bottom: 5px;" href="https://app.charterrode.com/">Sign In</a>
                </div>
              </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12" style="color:#bfbfbf;">
            <div class="lead mt-3" style="line-height: 24px; font-size: 18px;">SailingResume.io is a reference implementation for any individual or company that wishes to build their own <a href="https://www.charterrode.com/article/sailing-resume">Sailing Resume</a> viewer app on top of the <a href="https://blocks.charterrode.com">Sailing Resume Blockchain</a>.</div>
            <div class="lead mt-3" style="line-height: 24px; font-size: 18px;"><a href="https://www.charterrode.com">Charter Rode</a> is building the <a href="https://blocks.charterrode.com">Sailing Resume Blockchain</a> – a secure, transparent, efficient, and tamper-proof maritime resume system enabled by blockchain technology.</div>
            <div class="lead mt-3" style="line-height: 24px; font-size: 18px;">The <a href="https://blocks.charterrode.com">Sailing Resume Blockchain</a> offers sailors a trusted and secure <a href="https://www.charterrode.com/article/sailing-resume">sailing resume</a> that anyone can confidently use to determine their seaworthiness.</div>
            <div class="lead mt-3" style="line-height: 24px; font-size: 18px;">Banks, insurance companies, charter companies, education providers, and other organizations use the <a href="https://blocks.charterrode.com">Sailing Resume Blockchain</a> to grant credentials, verify sea time, evaluate competencies, and provide value-added services to mariners worldwide.</div>
            <div class="lead mt-3" style="line-height: 24px; font-size: 18px;">For more information please visit the <a href="https://www.charterrode.com">Charter Rode</a> website and read the <a href="https://www.charterrode.com/article/">Charter Rode Articles</a>. The <a href="https://www.charterrode.com/bex/">Charter Rode Block Explorer</a> is also an easy way to view the <a href="https://blocks.charterrode.com">Sailing Resume Blockchain</a>, or view the raw block data for the <a href="https://blocks.charterrode.com/3c1fe0746741ff48d67ce07f87c7bc51125c9d8abc78f782bf220399dd99b1c4">Sailing Resume Blockchain Genesis Block</a>.</div>
          </div>
        </div>

      </div>

      <div class="pt-5">
        <div class="footerBlock" style="width: 100%;">
          <hr style="margin:0px;">
          <div style="font-size:10px;padding:10px;color:#ddd">@ Copyright 2021-{{copyYear}}, Charter Rode. Weigh anchor. Explore the world. <a href="https://www.charterrode.com" style="color:#ddd;font-weight:bold;text-decoration: none;">Charter Rode</a> is building the world's first blockchain for your sailing experience.</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from "../api/api";

export default {
  name: 'Home',
  data() {
    return {
      copyYear: new Date().getFullYear(),
      sailorId: undefined,
      validation: {
        sailorId: undefined
      }
    }
  },
  mounted() {
    // now get the drop downs
    api.getUIDropdowns().then(uiDropdowns => {
      if (uiDropdowns) {
        this.$store.commit('setUiDropdowns', uiDropdowns)
      }
      this.$router.push('/')
    }).catch(error => {
      console.log(error)
      this.$router.push('/')
    })
  },
  methods: {
    lookupResume() {
      this.$router.push('/'+this.sailorId)
    }
  },
  components: {

  }
}
</script>

<style>
.cr-background-sail {
  /*background-image: url(/resume/sailHome.jpg);*/
  background-image: url(/sailHome.jpg);
  /*opacity: 10%;*/
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#ffffff;
  z-index: -1;
  overflow: hidden;
}

a {
  color: #bfbfbf;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #ffffff;
}

.cr-background-sail-overlay {
  background-color: rgba(0,0,0,.70);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  /*z-index: -1;*/
  z-index: 1!important;
}

.cr-logo {
  height: 20px;
}

.formLabel {
  color:#666;
  text-transform: uppercase;
}

.helpBlock {
  font-size:12px;
}

.footerBlock {
  color: #ffffff;
}
</style>
