<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style>
body {
  color: #444;
}

.charterRodeBtn:hover, .charterRodeLink:hover {
  cursor: pointer;
}
.charterRodeHelpText {
  color: #aaa;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-bottom: 0.125rem!important;
}
</style>
